<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md="7">
                            <h4>Type - Rules Mapping</h4>
                        </CCol>
                        <CCol md="5">
                            <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                        </CCol>
                    </CRow>
                </CCardHeader>

                <CCardBody>
                    <CRow>
                        <CCol col="12" class="text-left">
                            <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol md="7">
                            <CSelect
                                label="Transaction Type"
                                @change="onChangeTrType"
                                :options="optTrType"
                                horizontal>
                            </CSelect>
                        </CCol>
                        <CCol md="3">
                            <CButton :disabled="!isDisabled"
                                v-on:click="actionNewRule()"
                                style="width:120px" color="primary">
                                New Rule
                            </CButton>
                        </CCol>
                    </CRow>

                    <div class="table-responsive table">
                        <b-table striped hover :items="items" :fields="fields" :current-page="currentPage"
                            :per-page="perPage" :filter="filter" sort-by="createDate" :sort-asc="true">

                            <template v-slot:cell(createDate)="{ item }">
                                {{ item.createDate | dateWithTime }}
                            </template>

                            <template v-slot:cell(actions)="{ item }">
                            <b-dropdown variant="info" text="Actions" size="md">
                                <router-link :to='"/edittyperulesmap/" + item.id' tag="b-dropdown-item" > Edit </router-link>
                                <router-link to="#" exact v-on:click.native="delTrsRule(item.id)" tag="b-dropdown-item"> Delete </router-link>
                            </b-dropdown>
                            </template>

                        </b-table>
                    </div>

                    <div>
                        <b-row>
                            <b-col sm="3">
                                <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage"
                                    v-model="currentPage" />
                            </b-col>
                            <b-col sm="9">
                                <b-form-select
                                    :options="[{ text: 10, value: 10 }, { text: 20, value: 20 }, { text: 50, value: 50 }, { text: 100, value: 100 }]"
                                    v-model="perPage" style="width:100px;">
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import FraudService from '@/api/FraudService.js';

export default {
    name: "fraudallrule",
    data() {
        return {
            isDisabled:false,
            spinner: false,
            trType: null,
            optTrType: [],
            rulelist:[],
            items: [],
            fields: [
                {
                  key: 'ruleCode',
                  label : 'Rule Code',
                  sortable: true
                },
                {
                  key: 'name',
                  label : 'Rule Name',
                  sortable: true
                },
                {
                  key: 'description',
                  label : 'Rule Description',
                  sortable: true
                },
                {
                  key: 'fields',
                  label : 'Parameters',
                  sortable: true
                },
                {
                  key: 'status',
                  label : 'Status',
                  sortable: true
                },
                {
                  key: 'createDate',
                  label : 'Created Date',
                  sortable: true
                },
                {
                key: 'actions',
                label: 'Actions'
                }
            ],
            rules:[],
            currentPage: 1,
            perPage: 10,
            filter: null,
            seen: false,
            msg: '',
            color: ''
        };
    },
    filters: {
        upper(value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
        lower(value) {
            if (!value) return ''
            value = value.toString()
            return value.toLowerCase()
        },

        dateSubstr(value) {
            if (!value) return ''
            value = value.toString()
            return value.substring(0, 10)
        },

        IdSubstr(value) {
            if (!value) return ''
            value = value.toString()
            return value.substring(0, 5) + '...'
        },
        dateWithTime(value) {
            if (!value) return ''
            var temp = value.toString().split(".");
            value = temp[0].replace("T", " ");
            return value;
        },

    },
    created() {
      this.getAllRule();
      this.getAllTransactionTypes();
      var trtypeCode = this.$route.params.trTypeCode;
      if(trtypeCode){
         this.getRuleByTransactionType(trtypeCode);
         this.trType     = trtypeCode;
         this.isDisabled = true;
      }
    },
    methods: {
        getFieldByRule(ruleCode){
          let fields = [];
          switch(ruleCode) {
              case "RULE0001":
                //sameSenderGapSeconds
                fields.push('Transaction Velocity Break Interval by a User');
              break;
              case "RULE0002":
                //sameSenderReceiverGapSeconds
                fields.push('Beneficiary Velocity Break Interval by a User');
              break;
              case "RULE0003":
                //singleTransactionAmount
                fields.push('Max. Single Transaction Amount Per User');
              break;
              case "RULE0004":
                //prevDayTotalExceedPercentage, totalTransSafetyValue
                fields.push('Current vs Previous Day Value Excess Permitted (in %)');
                fields.push('Current Day Max Safety Value');
              break;
              case "RULE0006" :
                fields.push('NRIC List');
                fields.push('UEN List');
                fields.push('Email List');
                fields.push('Receiver Name List');
                fields.push('Bank Name List');
                fields.push('Mobile Number List');
                fields.push('Account Number List');
              break;
              default:
                //globalTransSafetyValue
                fields.push('Global Trans Safety Value');
          }
          return fields;
        },

        onChangeTrType(a) {
            this.trType = a.target.value;
            if(this.trType !== null && this.trType !== ''){
               this.isDisabled = true;
               this.getRuleByTransactionType(this.trType);
            } else {
               this.isDisabled = false;
               this.items = [];
            }
        },

        actionNewRule(){
           var trtypeCode = this.trType;
           this.$router.push('/createtyperulesmap/'+ trtypeCode);
        },

        delTrsRule(id){
           this.removeTransactionRule(id);
        },

        getAllTransactionTypes: function () {
            FraudService.getAllTransactionTypes().then(resp => {
                var afTrsType = resp;  var afTrsTypeList = [];
                afTrsTypeList.push({ value: null, label: '--- Select ---' });
                for (var key in afTrsType) {
                    var row  = afTrsType[key];
                    afTrsTypeList.push({ value: row.code, label: row.name });
                }
                this.optTrType = afTrsTypeList;
            }, error => {
                this.spinner = false;
            });
        },

        removeTransactionRule: function (id) {
            FraudService.removeTransactionRule(id).then(resp => {
                if(resp.message=='Success'){
                   this.getRuleByTransactionType(this.trType);
                }
            }, error => {
                this.spinner = false;
            });
        },

        getRuleByTransactionType: function (code) {
            FraudService.getRuleByTransactionType(code).then(resp => {

                FraudService.getAllRule().then(res => {
                    let ruleList = {};
                    for (var key in res) {
                        var row = res[key];
                        ruleList[row.ruleCode] = row;
                    }
                    for (var key in resp) {
                        var row = resp[key];
                        row['name'] = ruleList[row.ruleCode].name;
                        row['description'] = ruleList[row.ruleCode].description;
                        row['fields'] = this.getFieldByRule(row.ruleCode);
                    }
                    this.items = resp;
                }, error => {
                    this.spinner = false;
                });
            }, error => {
                this.spinner = false;
            });
        },

        getAllRule: function() {
          FraudService.getAllRule().then(resp => {
            let ruleList = {};
            for (var key in resp) {
               var row = resp[key];
               if(!this.rulelist.includes(row.ruleCode)){
                  ruleList[row.ruleCode] = row;
               }
            }
            this.rules = ruleList;
          }, error => {
            this.spinner = false;
          });
        }
    }
};
</script>
<style>.table {
    overflow-y: scroll;
}</style>
